<template>
  <div>
    <div ref="print" class="print">
      <header class="header">
        <div class="content">
          <div class="header__row">
            <div class="header__logo">
              <img :src="require('@/assets/img/logo.svg')" />
            </div>
            <div class="header__contacts">
              <div class="header__contact">
              
                <span>8 (846) 231-65-55</span>
              </div>
              <div class="header__contact">
              

                <span>г. Самара, Волжский проспект, д. 37</span>
              </div>
              <div class="header__contact">
                

                <span>an5zvezd.ru</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <section class="main-info">
          <div class="content">
            <h1 class="main-info__title">
              {{ getCustomProp("presentationNameFieldId") }}
            </h1>
            <h2 class="main-title">Общая информация</h2>
            <div class="main-info__address">
              <span>Адрес:</span>
              {{ getCustomProp("address") }}
            </div>
            <div class="main-info__props">
              <div class="main-info__prop">
                <div class="main-info__prop-name">Общая площадь</div>
                <div class="main-info__prop-value">
                  {{ getCustomPropById(626451) }} м²
                </div>
              </div>
              <div
                class="main-info__prop"
                v-if="getCustomPropById(643299) && getCustomPropById(643301)"
              >
                <div class="main-info__prop-name">Этаж/Этажность</div>
                <div class="main-info__prop-value">
                  {{ getCustomPropById(643299) }}/{{
                    getCustomPropById(643301)
                  }}
                </div>
              </div>
              <div class="main-info__prop">
                <div class="main-info__prop-name">
                  Стоимость/Стоимость за м²
                </div>
                <div class="main-info__prop-value">
                  {{
                    new Intl.NumberFormat("ru", {
                      style: "decimal",
                      maximumFractionDigits: 0,
                    }).format(getCustomPropById(637975))
                  }}
                  ₽/{{
                    new Intl.NumberFormat("ru", {
                      style: "decimal",
                      maximumFractionDigits: 0,
                    }).format(
                      getCustomPropById(637975) / getCustomPropById(626451)
                    )
                  }}
                  ₽
                </div>
              </div>
            </div>
            <h2 class="main-title">Описание объекта</h2>
            <div
              class="main-info__description"
              v-html="getCustomPropById(643309)"
            ></div>
          </div>
        </section>
        <section class="images">
          <div class="content">
            <div class="images__row">
              <div
                class="images__img-container"
                v-for="(img, ind) in images.slice(0, 12)"
                :key="ind"
              >
                <img
                  class="images__img"
                  :src="
                    Item.config.imagesHost + '/' + currentLead.id + '/' + img
                  "
                />
              </div>
            </div>
            <div class="html2pdf__page-break" v-if="images.length > 12"></div>
            <div class="images__row" v-if="images.length > 12">
              <div
                class="images__img-container"
                v-for="(img, ind) in images.slice(12)"
                :key="ind"
              >
                <img
                  class="images__img"
                  :src="
                    Item.config.imagesHost + '/' + currentLead.id + '/' + img
                  "
                />
              </div>
            </div>
          </div>
        </section>
        <footer class="agent-section">
          <div class="content">
            <div class="agent-section__agent">
              <div class="agent__info-container">
                <div class="agent__name-container">
                  <div class="agent__name">
                    {{ Item.agent.name }}
                  </div>
                  <div class="agent__position">
                    Специалист по продаже и аренде недвижимости
                  </div>
                </div>
                <div class="agent__contacts-container">
                  <a :href="'mailto:' + Item.agent.login" class="agent__email">
                    
                    <span>{{ Item.agent.login }}</span>
                  </a>
                  <a
                    :href="'tel:' + Item.agent.phone_number"
                    class="agent__phone"
                  >
                    
                    <span>
                      {{ Item.agent.phone_number }}
                    </span>
                  </a>
                </div>
                <div class="agent__requisites">
                  <div class="agent__requisite">
                    ООО АН « Пять звезд»<br />
                    ОГРН 1106316013638,<br />
                    ИНН 6316158605, КПП 631501001<br />
                    ю/а 443099, Самарская обл.,<br />
                    г. Самара, Волжский проспект,<br />
                    дом 37, офис 1
                  </div>
                  <div class="agent__requisite">
                    р/c №40702810554400015858<br />
                    в Самарском отделении №6991<br />
                    ПАО «Сбербанк России»,<br />
                    БИК 043601607,<br />
                    к/с 30101810200000000607
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
export default {
  name: "PrintTemplate",
  data: () => ({
    mapMainSettings: {
      apiKey: "2fc16756-64e8-4a86-b899-d377c0b5ca05",
      lang: "ru_RU",
      coordorder: "latlong",
      enterprise: false,
      version: "2.1",
    },
    mapSettings: {
      zoom: 15,
      controls: ["geolocationControl", "zoomControl"],
    },
    icon: {
      layout: "default#image", // 'default#imageWithContent' для использования с контентом
      imageHref:
        "data:image/svg+xml,%3Csvg width='46' height='56' viewBox='0 0 46 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22.9744' cy='22.9744' r='22.9744' fill='%23EE3686'/%3E%3Cpath d='M22.9744 56L42.8707 29.0769H3.07798L22.9744 56Z' fill='%23EE3686'/%3E%3Cpath d='M16.0579 17.6335H8.16342C8.08045 17.8956 8.08297 17.8854 8 18.1475L16.9253 24.6666L13.5261 35.2952C13.7474 35.4555 13.7373 35.4504 13.9586 35.6107L20.2767 30.9287L16.0554 17.6335H16.0579Z' fill='white'/%3E%3Cpath d='M28.744 24.6663L37.7196 18.1472C37.6366 17.8851 37.6391 17.8953 37.5561 17.6332H29.6642L25.4429 30.9284L31.761 35.6104C31.9822 35.4475 31.9722 35.4552 32.1934 35.2923L28.744 24.6663Z' fill='white'/%3E%3Cpath d='M22.5997 7L19.2207 17.6337L22.5997 28.2673H23.1202L26.4992 17.6337L23.1202 7H22.5997Z' fill='white'/%3E%3C/svg%3E%0A", // адрес изображения или data:image/svg+xml;base64
      imageSize: [43, 55], // размер иконки в px
      imageOffset: [0, 0], // смещение иконки в px,
      /* Следующие поля актуальны для layout: 'default#imageWithContent' */
      content: "", // содержимое контента
      contentOffset: [0, 0], // смещение контента в px,
      contentLayout: "", // строковый HTML шаблон для контента
    },
  }),
  components: {
    yandexMap,
    ymapMarker,
  },
  computed: {
    ...mapGetters(["Item"]),
    currentLead() {
      return this.Item.leads[0];
    },
    images() {
      let imagesJSON = this.getCustomPropById(669929);
      return Object.keys(JSON.parse(imagesJSON));
    },
    mapMarker() {
      return {
        markerId: 1,
        hideIconOnBalloonOpen: false,
        coords: [
          this.getCustomProp("latitude"),
          this.getCustomProp("longitude"),
        ],
        options: {
          hideIconOnBalloonOpen: false,
          syncOverlayInit: true,
          balloonOffset: [0, -30],
        },
      };
    },
  },
  methods: {
    ...mapActions(["fetchItem"]),
    getCustomProp(propName) {
      let prop = this.currentLead.custom_fields_values.find(
        (field) => field.field_id === this.Item.config[propName]
      );
      if (prop) {
        return prop.values[0].value;
      }
      return false;
    },
    getCustomPropById(id) {
      let prop = this.currentLead.custom_fields_values.find(
        (field) => field.field_id === id
      );
      if (prop) {
        return prop.values[0].value;
      }
      return false;
    },
  },
  async created() {
    // await this.fetchItem({
    //   domain: this.$route.params.domain,
    //   personalId: this.$route.params.id,
    //   leadId: this.$route.query.ids,
    // });
  },
  mounted() {
    let print = this.$refs.print
    if(print) {
      let printRect = print.getBoundingClientRect()
      print.style.minHeight = Math.ceil(printRect.height/2035)*2035+"px"
    }
  },
};
</script>

<style lang="scss" scoped>
.print {
  min-height: 2035px;
  border-right: 50px solid #ee3686;
  display: flex;
  flex-direction: column;
  // &::before {
  //   width: 50px;
  //   height: 3850px;
  //   background-color: #ee3686;
  //   position: fixed;
  //   top: 0;
  //   left: 1650px;
  //   display: block;
  //   content: "";
  // }

  & main {
    flex-grow: 1;
    height: 100%;
  }
}
.header {
  padding: 20px 0;
  background-color: #fff;

  &__logo {
    flex-shrink: 0;

    & img {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__contacts {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }

  &__contact {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 0;
    color: #000000;

    & svg {
      flex-shrink: 0;
      margin-right: 8px;

    }

    & span {
      white-space: nowrap;
    }
  }
}
main {
  display: flex;
  flex-direction: column;
}
.main {
  &-title {
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 500;
    line-height: 37px;
    text-align: left;
    text-transform: uppercase;
  }

  &-info {
    padding: 20px 0 40px;

    &__title {
      margin-bottom: 40px;
      margin-top: 20px;
      font-size: 64px;
      font-weight: 500;
      line-height: 75px;
      text-align: left;
    }

    &__address {
      & span {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
      }
      font-size: 28px;
      font-weight: 500;
      line-height: 32px;
    }

    &__props {
      margin-bottom: 40px;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 70px;
    }

    &__prop {
      &-name {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
      }

      &-value {
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
      }
    }

    &__description {
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;

      text-align: justify;
    }
  }
}

.images {
  &__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  &__img-container {
    width: 100%;
    height: 240px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.agent-section {
  padding: 40px 0;
  margin-top: auto;

  &__agent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.agent {
  &__info-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__name {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  &__position {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }

  &__contacts-container {
    margin-left: 40px;
    margin-right: 40px;
  }

  &__email {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    line-height: 24px;

    & svg {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }

  &__phone {
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & svg {
      flex-shrink: 0;
      margin-right: 8px;
    }

    & span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__requisites {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 70px;
    flex-shrink: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
}
</style>

<style lang="scss">
.main-info__description {
  p {
    display: inline;
    margin-bottom: 0;
  }
}
</style>