import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import PrintView from '../views/Print.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:domain/:id/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/print/:domain/:id/',
    name: 'Print',
    component: PrintView
  },
  {
    path: '*',
    name: '404',
    component: HomeView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (
        to.hash.replace("#", "") &&
        document.getElementById(to.hash.replace("#", ""))
      ) {
        return {
          selector: to.hash,
        };
      } else {
        if (to.path !== from.path) {
          return { x: 0, y: 0 };
        }
      }
    }
  },
  routes
})

export default router
