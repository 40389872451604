import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    item: {}
  },
  getters: {
    Item: s => s.item
  },
  mutations: {
    setItem(s, item) {
      s.item = item
    },
  },
  actions: {
    async fetchItem(ctx, {domain, personalId, leadId}) {
      await fetch(`https://io.winwinapp.ru/api/${domain}/${personalId}/?ids=${leadId}`, {
        method: "GET"
      }).then(async (response) => {
        if(response.status === 200) {
          const result = await response.json()
          ctx.commit('setItem', result.data)
        } else {
          ctx.commit('setItem', false)
        }
      })
    }
  },
  modules: {
  }
})
