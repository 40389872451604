<template>
  <PrintTemplate />
</template>

<script>
import PrintTemplate from '@/components/PrintTemplate.vue'
export default {
  name: "Print",
  components: {
    PrintTemplate
  }
}
</script>

<style>

</style>